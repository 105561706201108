@import '../../style/theme/index.scss';

.avatar{
    display: inline-block;
    cursor: pointer;
    text-align: center;
    text-rendering: optimizeSpeed;
    background-size: cover;                    
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden; 
    position: relative;
    transition: transform 2s;
    transition-delay: 4s;
    border-radius: 50%;
    border:solid transparent;

    img{
        width: 100%;
        height: 100%;
        text-align: center;
        object-fit: cover;
    }
    &.avatar-squared{
        border-radius: $radius-1;
    }   

    &.avatar-style-rainbow-0{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $rainbow-0;
        &.avatar-img{
            background: desaturate(lighten($rainbow-0, 30%), 50%);
        }
        
    };
    &.avatar-style-rainbow-1{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $rainbow-1;
        &.avatar-img{
            background: desaturate(lighten($rainbow-1, 30%), 50%);
        }
        
    };
    &.avatar-style-rainbow-2{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $rainbow-2;
        &.avatar-img{
            background: desaturate(lighten($rainbow-2, 30%), 50%);
        }
        
    };
    &.avatar-style-rainbow-3{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $rainbow-3;
        &.avatar-img{
            background: desaturate(lighten($rainbow-3, 30%), 50%);
        }
        
    };
    &.avatar-style-rainbow-4{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $rainbow-4;
        &.avatar-img{
            background: desaturate(lighten($rainbow-4, 30%), 50%);
        }
        
    };
    
    &.avatar-style-default{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $brand-primary-500;
        &.avatar-img{
            background: desaturate(lighten($brand-primary-500, 30%), 50%);
        }
        
    };

    &.avatar-style-rainbow-0{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $rainbow-0;
        &.avatar-img{
            background: desaturate(lighten($rainbow-0, 30%), 50%);
        }
        
    };
    &.avatar-style-rainbow-1{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $rainbow-1;
        &.avatar-img{
            background: desaturate(lighten($rainbow-1, 30%), 50%);
        }
        
    };
    &.avatar-style-rainbow-2{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $rainbow-2;
        &.avatar-img{
            background: desaturate(lighten($rainbow-2, 30%), 50%);
        }
        
    };
    &.avatar-style-rainbow-3{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $rainbow-3;
        &.avatar-img{
            background: desaturate(lighten($rainbow-3, 30%), 50%);
        }
        
    };
    &.avatar-style-rainbow-4{
        color: $neutral-greys-200;
        fill: $neutral-greys-200;
        background: $rainbow-4;
        &.avatar-img{
            background: desaturate(lighten($rainbow-4, 30%), 50%);
        }
        
    };
    
    &.avatar-style-neutral{
        color: $neutral-greys-800;
        fill: $neutral-greys-800;
        
        background: $neutral-greys-300;
        &.avatar-img{
            background: desaturate(lighten($brand-primary-500, 30%), 50%);
        }
        
    };

    &.avatar-size-40{
        height: 36px;
        width: 36px;
        line-height: 36px;
        font-size: 14px;
        border-width: 2px;
    }
    
    &.avatar-size-35{
        height: 31px;
        width: 31px;
        line-height: 31px;
        font-size: 12px;
        border-width: 2px;
    }
    &.avatar-size-25{
        height: 21px;
        width: 21px;
        line-height: 21px;
        font-size: 8px;
        border-width: 2px;
    }

    &.avatar-font-size-11{
        font-size: 1px !important;
    }
}
