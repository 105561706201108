@import './../../../style/theme/index.scss';
.buffl-badge {
    
    display: inline-block;
    
    padding: 5px 15px;
    border-radius: $radius-1;
    text-transform: uppercase;
    font-size: $font-size-75;
    margin-right: $space-0;
    font-weight: 600;
    &.bb-style-default{
        background: $brand-primary-500;
        color: #fff;
    }
    &.bb-style-success{
        background: $brand-primary-500;
        color: #fff;
    }
    &.bb-style-danger{
        background: $status-info-700;
        color: #fff;
    }
    &.bb-style-secondary{
        background: $brand-secondary-500;
        color: #fff;
    }
    &.bb-style-neutral{
        background: $neutral-greys-400;
        color:$brand-secondary-500;
    }
    &.bb-style-warning{
        background: $status-warning-300;
        color: #fff;
    }
}