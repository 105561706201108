@import '../../style/theme/index.scss';

.comment-wrapper{
    margin-bottom: $space-2;
    .comment-body{
        padding: 16px 8px;
        background: $neutral-greys-200;
        border-radius: 6px;
        
        margin-bottom: $space-0;
        .text-style-description{
            color: $neutral-greys-600;
            font-size: $font-size-100;
        }
        
    }

    .comment-author{
        display: flex;
        margin-bottom: 8px;
        .comment-author-label{
            line-height: 26px;
            font-size: $font-size-100;
            margin-left: $space-0;
            font-weight: $font-weight-semibold;
            color: $neutral-greys-600;
        }
    }

    .date{
        text-align: right;
        flex-grow: 1;
        line-height: 26px;
    }
}