@import '../../style/theme/index.scss';

.dialog-header{
    border-radius: $radius-2 $radius-2 0px 0px;
    align-items: center;
    display: flex;
    background: $neutral-greys-100;
    padding: $space-2;
    padding-bottom: $space-1;
    
    .title-wrapper {
        flex-grow: 1;
    }
    .icon-container{
        display: flex;
    }
    h3 {
        font-size: $font-size-500;
        font-weight: $font-weight-bold;
        color: $neutral-greys-600;
        font-family: $font-sans;
    }
}
.dialog-content {
    background: transparent;
    padding: $space-2;
    padding-top: $space-0;
}
.dialog-footer {
    display: flex;
    padding: $space-1 $space-2;
    border-radius: 0px 0px $radius-2 $radius-2;
    background: $neutral-greys-200;
}

.success-dialog-content{
    text-align: center;
    img{
        height: 200px;
        margin: $space-2;
    }
    .explanation-label{
        line-height: $line-height-2;
    }
}
