@import '../../style/theme/index.scss';
.flex {
    display: flex;
}
.buffl-drawer-ui-wrap  {
    padding: $space-0 0;
    line-height: 35px;
    .col1 {
        padding-top: 5px;
    }
    .col2 {
        flex-grow: 1;
        padding: 0 10px; 
    }
    .col3 {
        text-align: right;
        opacity:0;
        transition: opacity 0.15s ease-in-out;
    }
    &:hover {
        .col3 {
            opacity: 1;
        }
    }
}
.buffl-drawer {
    width: 320px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;    
    background: $neutral-greys-100;
    
    box-sizing: border-box;
    position: relative;
    .bd-header {
        display: flex;
        padding: $space-2;
        padding-bottom: $space-0;
        .bd-header-left {
            text-align: left;
            flex-grow: 1;
            line-height: 1.6em;
        }
        .bd-header-right {
            text-align: right;
        }
        button {
            transform: translateX(8px) translateY(-8px);
        }
        h3 {
            font-weight: 500;
            font-size: $font-size-300;
        }
    }
    .buffl-drawer-master {
        position: absolute;
        top:0;
        left:0;
        width: 320px;
        height: 100vh;
    }
    .buffl-drawer-master-hide{
        opacity: 0.2;
        left: -320px;
        transition: 0.5s all ease;
    }
    .buffl-drawer-master-show{
        transition: 0.5s all ease;
        left:0px;
    }
    .buffl-drawer-slave{
        background: #fff;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 320px;
        height: 100vh;
        .bd-slave-header {
            display: flex;
            padding: $space-2 0px;
            padding-bottom: $space-0;
            .bd-header-left {
                text-align: left;
                line-height: 1.6em;
                flex-grow: initial !important;
            }
            .bd-header-right {
                margin-left: $space-1;
                text-align: right;
            }
            button {
                transform: translateX(8px) translateY(-8px);
            }
            h3 {
                font-weight: 500;
                font-size: $font-size-300;
            }
        }

        &.buffl-drawer-slave-show{
            left:0;
            transition: 0.4s all ease-out;
            box-shadow: none;


        }
        &.buffl-drawer-slave-hide{
            transition: 0.5s all ease;
            left: 320px;
        }
        
    }
    
    .bd-drawer-page{
        width: 320px;
         height: 100vh;
         display: flex;
         flex-direction: column;
         box-sizing: border-box;
    }

    

    
    .bd-content {
        flex-grow: 1;
        .bd-scrollbar-content {
            padding: $space-2;
            padding-top:0;
        }

        .drawer-section{
            margin: $space-3 0;
        }
    }

    .drawer-section{
        .drawer-section-title{
            margin-bottom: $space-0;
            .text-style-section-headline{
                color: $neutral-greys-600;
            }
        }
        .drawer-section-body{
            
        }

        margin-bottom: $space-1;
    }
    .drawer-item {
        .drawer-action-description{
            margin-bottom: $space-0;    
        }
        margin-bottom: $space-0;
        .drawer-item-title{
            .text-style-caption{
                color: $neutral-greys-500;
                font-weight: 600;
            }
            margin-bottom: 8px;
        }
        .drawer-item-body{
            .text-style-description{
                color: $neutral-greys-600;
            }
        }
    }

    .drawer-comments-empty{
        padding: $space-0 0;
    }

    .drawer-form{
        .drawer-form-item {
            
            margin-bottom: $space-1;
            box-sizing: border-box;
            
            label {
                font-size: $font-size-100;
                color: $neutral-greys-400;
                margin-bottom: $space-0;
                display: block;
            }
            span {
                font-size: $font-size-300;
                color: $neutral-greys-700;
                display: block;
            }
           
        }
    }
    
}