@import '../../style/theme/index.scss';

.confirmation-dialog-content{
    text-align: center;
    img{
        height: 200px;
        margin: $space-2;
    }
    .explanation-label{
        line-height: $line-height-2;
    }
}
