@import '../../style/theme/index.scss';

.main-content-layout {
    display: flex;
    flex: 1;
    height: 100vh;
    flex-direction:column;
    overflow: hidden;
}
.app-flex-wrap {
    height: 100vh;
    flex: 1;
    flex-direction: row;
    display:flex;
    background: $neutral-greys-200;
}