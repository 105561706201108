$font-sans: 'Open Sans', Lato, Inter, Roboto, Arial, sans-serif;;
$font-serif: Times, serif;


$font-size-75: 0.68em;
$font-size-100: 0.75em;
$font-size-200: 0.875em;
$font-size-300: 1em;
$font-size-400: 1.25em;
$font-size-500: 1.5em;
$font-size-600: 2em;
$font-size-650: 2.5em;
$font-size-700: 3em;

$line-height-0: 1em;
$line-height-1: 1.25em;
$line-height-2: 1.5em;
$line-height-3: 2.5em;

$font-weight-thin: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 800;

