@import '../../../style/theme/index.scss';

.auth-layout-background {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    z-index: 0;
    background: $neutral-greys-200;
}
.auth-layout-wrapper {
    position: absolute;
    z-index: 1;
    top:0;
    left:0;
    right: 0;
    min-height: 101vh;
    background: $neutral-greys-200;
    box-sizing: border-box;
    padding: $space-4 0;
    .auth-layout-page {
        width: 360px;
        background: #fff;
        margin: 0 auto;
        padding: $space-4 $space-4;
        border-radius: $radius-1;
        box-shadow: $dropshadow-100;
    }
}