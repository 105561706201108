$space-0: 0.5em;
$space-1: 1em;
$space-2: 1.5em;
$space-3: 2em;
$space-4: 3em;
$space-5: 6em;

$width-0: 0.5em;
$width-1: 1em;
$width-2: 1.5em;
$width-3: 2em;
$width-4: 3em;
$width-5: 6em;

$heigts-0: 0.5em;
$heigts-1: 1em;
$heigts-2: 1.5em;
$heigts-3: 2em;
$heigts-4: 3em;
$heigts-5: 6em;