@import '../../style/theme/index.scss';
.empty-state-wrapper{
    text-align: center;
    .empty-state-image{
        margin-top: 160px; 
        img{
            max-width: 120px;
        }
        margin-bottom: $space-1;
    }
}