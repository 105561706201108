.display-flex{
    display: flex;
}
.flex-wrap-wrap{
    flex-wrap: wrap;
}
.flex-justify-end {
    justify-content: flex-end;
}
.left-align{
    text-align: left;
}
.left {
    text-align: left;
}
.right {
    text-align: right;
}
.center {
    text-align: center;
}
.flex-grow-1 {
    flex-grow: 1;
}
.inself-left {
    display: inline-block;
    text-align: left;
}
.no-selection {
    ::-moz-selection {
        background: transparent;
      }
      
      ::selection {
        background: transparent;
      } 
}
.invisible {
    opacity:0;
}