@import '../../style/theme/index.scss';

.global-navigation{
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: 100vh;
    width:70px;
    background: $neutral-greys-700;
    .global-navigation-inner{
        width: 40px;
        margin-top: $space-2;    
        margin-left: auto;
        margin-right: auto;

        .global-navigation-main{
            margin-top: $space-1;
            .global-navigation-item{
                margin: $space-1 0px;
                a{
                    fill: $neutral-greys-600;
                    text-decoration: none;
                    display: flex;
                    border-radius: $radius-0;
                    font-family: $font-sans;
                    &:hover{
                        fill: $neutral-greys-300;
                        color: $neutral-greys-300;
                    };
                    &.active{
                        fill: $neutral-greys-300;
                        color: $neutral-greys-300;
                    };
                    .icon-wrapper{
                        padding:  $space-0;
                        width: 40px;
                        height: 40px;   
                        box-sizing: border-box;
                        .icon{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    
                }
            }
        }
    }
    
}



