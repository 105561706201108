@import '../../../style/theme/index.scss';

.hublayout-background {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    z-index: 0;
    background: $neutral-greys-200;
}
.hublayout-wrapper {
    position: absolute;
    z-index: 1;
    top:0;
    left:0;
    right: 0;
    min-height: 101vh;
    background: $neutral-greys-200;
    
}