@import '../../style/theme/index.scss';

.avatar-group{
    .avatar{
        border-color: $neutral-greys-200;
    }
    
    &.avatar-group-size-40{
        ul{
            margin:0px;
            padding:0px;
            list-style: none;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            left: -2px;
            li {
                position: relative;
                display: inline;
            }
            li:first-of-type {
                z-index: 5;
            }
            li:nth-of-type(2) {
                left: -10px;
                z-index: 4;
            }
            li:nth-of-type(3) {
                left: -20px;
                z-index: 3;
            }
            li:nth-of-type(4) {
                left: -30px;
                z-index: 2;
            }
            li:nth-of-type(5) {
                left: -40px;
                z-index: 1;
            }
        }
        .avatar{
            height: 36px;
            width: 36px;
            font-size: 14px;
            line-height: 36px;
            border-width: 2px;
        }
    }

    &.avatar-group-size-35{
        ul{
            margin:0px;
            padding:0px;
            list-style: none;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            left: -2px;
            li {
                position: relative;
                display: inline;
            }
            li:first-of-type {
                z-index: 5;
            }
            li:nth-of-type(2) {
                left: -8.75px;
                z-index: 4;
            }
            li:nth-of-type(3) {
                left: -17.5px;
                z-index: 3;
            }
            li:nth-of-type(4) {
                left: -26.25px;
                z-index: 2;
            }
            li:nth-of-type(5) {
                left: -35px;
                z-index: 1;
            }
        }
        .avatar{
            height: 31px;
            width: 31px;
            line-height: 31px;
            font-size: 12px;
            border-width: 2px;
        }
    }
   
    &.avatar-group-size-23{
        ul{
            margin:0px;
            padding:0px;
            list-style: none;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            left: -2px;
            li {
                position: relative;
                display: inline;
            }
            li:first-of-type {
                z-index: 5;
            }
            li:nth-of-type(2) {
                left: -5.75px;
                z-index: 4;
            }
            li:nth-of-type(3) {
                left: -11.5px;
                z-index: 3;
            }
            li:nth-of-type(4) {
                left: -17.25px;
                z-index: 2;
            }
            li:nth-of-type(5) {
                left: -24px;
                z-index: 1;
            }
        }
        .avatar{
            height: 21px;
            width: 21px;
            line-height: 21px;
            font-size: 8px;
            border-width: 2px;
        }
    }

    
    
}