@import '../../style/theme/index.scss';

.field-group{
    font-family: $font-sans;
    .label{
        display: inline-block;
        font-size: $font-size-200;
        font-weight: $font-weight-bold;
        color: $neutral-greys-500;
        margin-bottom: $space-0;
    }

    .error-wrapper { 
        text-align: right;
        @keyframes BakAppear {
            0% {
                transform: scale(0);
                opacity: 0.0;		
            }
            60% {
                transform: scale(1.1);	
            }
            80% {
                transform: scale(0.9);
                opacity: 1;	
            }	
            100% {
                transform: scale(1);
                opacity: 1;	
            }
        }
        .error{
            animation-name: BakAppear;
            -webkit-animation-name: BakAppear;
            display: inline-block;
            animation-duration: 0.75s;	
            -webkit-animation-duration: 0.75s;
        
            animation-timing-function: ease-in-out;	
            -webkit-animation-timing-function: ease-in-out;		
        
            visibility: visible !important;	
            margin-bottom: 1em;

            .error-label{
                display: inline-block;
                color: #fff;
                background:$brand-secondary-500;
                font-weight: $font-weight-bold;
                font-size: $font-size-100;
                padding: $space-0;
                border-radius: 3px;
            }
        }
        
    }
}


.field-error-wrapper { 
    text-align: right;
    @keyframes BakAppear {
        0% {
            transform: scale(0);
            opacity: 0.0;		
        }
        60% {
            transform: scale(1.1);	
        }
        80% {
            transform: scale(0.9);
            opacity: 1;	
        }	
        100% {
            transform: scale(1);
            opacity: 1;	
        }
    }
    .error{
        animation-name: BakAppear;
        -webkit-animation-name: BakAppear;
        display: inline-block;
        animation-duration: 0.75s;	
        -webkit-animation-duration: 0.75s;
    
        animation-timing-function: ease-in-out;	
        -webkit-animation-timing-function: ease-in-out;		
    
        visibility: visible !important;	
        margin-bottom: 1em;

        .error-label{
            display: inline-block;
            color: #fff !important;
            background:$status-danger-500;
            font-weight: $font-weight-regular;
            font-size: $font-size-100 !important;
            
            padding: $space-0;
            border-radius: 3px;
        }
    }
    
}
