@import '../../../style/theme/index.scss';

textarea.input-field {
    resize: none;
    height: $space-5;
}
.input-field{
    width: 100%;
    
    border-radius: $radius-2;
    background: $neutral-greys-250;
    color: $neutral-greys-500;
    border: 1px solid $neutral-greys-250;
    margin: $space-0 0;
    
    font-size: $font-size-200;
    font-family: $font-sans;
    padding: $space-1;
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid $neutral-greys-250;
    transition: all 0.2s;
    
    &.disabled{
        pointer-events: none;
        opacity: 0.7;
    }
    &::placeholder{
        font-weight: $font-weight-semibold;
        color: $neutral-greys-400;
    }
    &:focus{
        color: $neutral-greys-600;
        border: 1px solid rgba($brand-secondary-300, 0.9);
        box-shadow: 0px 0px 0px 3px rgba($brand-secondary-300, 0.2);
        background: #fff;
        outline: none;
    }
}   