@import '../../../style/theme/index.scss';

@mixin colorVariation($bg-color, $fg-color){
        background: $bg-color;
        color: $fg-color;
        fill: $fg-color;
        border-color: $fg-color;
}

@mixin lightenVariation($bg-color, $fg-color, $percent){
    background: lighten($bg-color, $percent);
    color: lighten($fg-color, $percent);
    fill: lighten($fg-color, $percent);
    border-color: lighten($fg-color, $percent);
}

@mixin darkenVariation($bg-color, $fg-color, $percent){
    background: darken($bg-color, $percent);
    color: $fg-color;
    fill: $fg-color, $percent;
    border-color: darken($fg-color, $percent);
}

@mixin buttonVariation($btn-color, $btn-type){
    
    $background: $btn-color;
    $border: none;
    $color: $neutral-greys-200;
    
    @if $btn-type == flat {
        $color: $btn-color;
        $background: transparent;
        $border: none;
        
    } @else if $btn-type == outlined {
        $color: $btn-color;
        $background: transparent;
        $border: 2px solid;

    } @else if $btn-type == pastel{
        $color: $btn-color;
        $background: $neutral-greys-300;
        $border: none;
    } 
    
    border: $border;
    @include colorVariation($background, $color);
    

    

    &:active, &.active, &.btn-active {
        @include lightenVariation($background, $color, 20%)
    }

    &:hover, &.hover {
        @include darkenVariation($background, $color, 20%)
    }
    &.btn-loading {
        background: scale-color($background, $lightness: -20%);
    }
}

.btn {
    font-family: $font-sans;
    font-weight: $font-weight-semibold;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    
    font-size: 1em;
    padding: 0.6em 1em;
    
    border-radius: 8px;
    span {
        position: relative;
        transition: all 0.05s ease-in-out;
        top:0px;
        opacity:1;
      }
    .svg-wrap-wrap {
        display: none;
    }
    &:disabled, &.btn-disabled {
        opacity: 0.5;
        user-select: none;
        cursor: default;
        
        pointer-events: none;
    }
    &:focus {
        outline: none;
    }

    &.btn-block {
        display: block;
        width: 100%
    }
    &.btn-loading, &.btn-loading:hover {
        .svg-wrap-wrap {
            display: block;
          }
          span {
            top: 3px;
            opacity:0;
          }
    }
    &.btn-rounded {
        border-radius: 100px;
    }
    &.btn-quadratic {
        padding: 0.6em;
    }
    &.btn-condensed {
        padding: 0.6em;
    }

    &.btn-spaced {
        padding-left: 1.5em !important;
        padding-right: 1.5em !important;
    }

    &.btn-sm{
        font-size: 1em;
        padding: 0.55em 0.875em;
        &.btn-quadratic {
            width: 1.75em;
            height: 1.75em;
            line-height: 1.75em;
            text-align: center;
            padding: 0em;
        }
        &.btn-condensed {
            padding: 0.55em;
        }
        .icon{
            height: 0.8em;
            width: 0.8em;
            &.icon-only{
                margin-right: 0px;
            }
        }
    }
    &.btn-medium{
        font-size: 1.25em;
        padding: 0.55em 0.875em;
        &.btn-quadratic {
            width: 1.6em;
            height: 1.6em;
            line-height: 1.6em;
            text-align: center;
            padding: 0em;
        }
        &.btn-condensed {
            padding: 0.55em;
        }
        .icon{
            height: 0.8em;
            width: 0.8em;
            &.icon-only{
                margin-right: 0px;
            }
        }
    }
    &.btn-large {
        font-size: 1.2em;
        line-height: 1.2em;
        padding: 0.55em 0.875em;
        &.btn-quadratic {
            width: 1.5em;
            height: 1.5em;
            line-height: 1.5em;
            text-align: center;
            padding: 0em;
        }
        &.btn-condensed {
            padding: 0.55em;
        }
        .icon{
            height: 0.8em;
            width: 0.8em;
            &.icon-only{
                margin-right: 0px;
            }
        }
    }
    &.btn-small{
        font-size: 0.85em;
        padding: 0.55em 0.875em;
        &.btn-quadratic {
            padding: 0.55em;
        }
        &.btn-condensed {
            padding: 0.55em;
        }
        .icon{
            height: 0.8em;
            width: 0.8em;
            &.icon-only{
                margin-right: 0px;
            }
        }
    }

    &.btn-no-padding{
        padding: 0 !important;
    }
    .btn-content{

    }
    .icon{
        height: 0.9em;
        width: 0.9em;
        margin-right: $space-0;
    }

    &.btn-icon-only{
        .icon{
            margin-right: 0px;
        }
    }
    


}

.btn-default-primary {
    @include buttonVariation($brand-primary-500, default)
}
.btn-default-secondary {
    @include buttonVariation($brand-secondary-500, default)
}
.btn-default-neutral {
    @include buttonVariation($neutral-greys-400, default)
}
.btn-default-danger {
    @include buttonVariation($status-danger-500, default)
}
.btn-pastel-secondary {
    @include buttonVariation($brand-secondary-500, pastel)
}

.btn-flat-neutral {
    @include buttonVariation($neutral-greys-500, flat)
}
.btn-flat-neutral-light {
    @include buttonVariation($neutral-greys-400, flat)
}
.btn-flat-primary {
    @include buttonVariation($brand-primary-500, flat)
}

.btn-outlined-neutral {
    @include buttonVariation($neutral-greys-500, outlined)
}
.btn-outlined-neutral-light {
    @include buttonVariation($neutral-greys-400, outlined)
}
.btn-blank-neutral {
    @include buttonVariation($neutral-greys-400, flat);
    padding: 0.5em;
    &:active {
    fill: $neutral-greys-500 !important;
    }
    &:hover {
        background: $neutral-greys-250;
    }
}




