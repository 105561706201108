@import '../../../style/theme/index.scss';

.user-select-option{
    height: 64px;

    .avatar-wrapper{
        
        margin-top: 2px;
        float: left;
        .avatar{
            height: 24px;
            width: 24px;
            line-height: 24px;
            font-size: 10px;
            margin: auto 0;
            border-width: 2px;
        }
    }
    .user-info-wrapper{
        line-height: 32px;
        margin-left:1em;
        float: left;
    }
}