@import '../../style/theme/index.scss';

.alert-box{
    padding: 24px;
    background: $status-warning-300;
    border-radius: 6px;
    .alert-label{
        font-weight: $font-weight-bold;
        color: $neutral-greys-700;
        font-size: $font-size-200;

    }
}