@import url(https://rsms.me/inter/inter.css);
.display-flex {
  display: flex; }

.flex-wrap-wrap {
  flex-wrap: wrap; }

.flex-justify-end {
  justify-content: flex-end; }

.left-align {
  text-align: left; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.center {
  text-align: center; }

.flex-grow-1 {
  flex-grow: 1; }

.inself-left {
  display: inline-block;
  text-align: left; }

.no-selection ::-moz-selection {
  background: transparent; }

.no-selection ::selection {
  background: transparent; }

.invisible {
  opacity: 0; }

.icon {
  height: 0.9em;
  width: 0.9em;
  top: 0.11em;
  position: relative; }

.text {
  margin: 0;
  font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif; }
  .text a {
    cursor: pointer; }
  .text.truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .text.text-style-body {
    font-size: 1em;
    font-weight: 400;
    color: #6F7898;
    font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif; }
    .text.text-style-body a {
      fill: black;
      background: red;
      color: black; }
      .text.text-style-body a:hover {
        fill: black;
        background: white;
        color: black; }
      .text.text-style-body a.active {
        fill: 'red';
        background-color: white;
        color: red; }
  .text.text-style-link {
    text-decoration: none;
    font-size: 0.875em;
    fill: #6F7898;
    color: #6F7898;
    font-weight: 100; }
    .text.text-style-link:hover {
      fill: #151935;
      color: #151935; }
    .text.text-style-link.active {
      fill: #6F7898;
      color: #6F7898; }
  .text.text-style-headline {
    font-size: 1.5em;
    font-weight: 700;
    color: #32395E;
    font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif; }
  .text.text-style-sub-headline {
    font-size: 1.25em;
    display: block;
    font-weight: 600;
    line-height: 2.5em;
    color: #32395E;
    font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif; }
  .text.text-style-section-headline {
    font-size: 1em;
    font-weight: 700;
    color: #6F7898;
    font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif; }
  .text.text-style-caption {
    font-size: 0.68em;
    color: #6F7898;
    font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif;
    font-weight: 600;
    letterSpacing: '0.015em'; }
  .text.text-style-description {
    font-size: 0.875em;
    color: #AAB5DB;
    font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif;
    font-weight: 600; }
  .text.text-font-size-75 {
    font-size: 0.68em; }
  .text.text-font-size-100 {
    font-size: 0.75em; }
  .text.text-font-size-200 {
    font-size: 0.875em; }
  .text.text-font-size-300 {
    font-size: 1em; }
  .text.text-font-size-400 {
    font-size: 1.25em; }
  .text.text-font-size-500 {
    font-size: 1.5em; }
  .text.text-font-size-600 {
    font-size: 2em; }
  .text.text-font-size-700 {
    font-size: 3em; }
  .text.text-font-weight-thin {
    font-weight: 300 !important; }
  .text.text-font-weight-regular {
    font-weight: 400 !important; }
  .text.text-font-weight-semibold {
    font-weight: 600 !important; }
  .text.text-font-weight-bold {
    font-weight: 700 !important; }
  .text.text-line-height-0 {
    line-height: 1em; }
  .text.text-line-height-1 {
    line-height: 1.25em; }
  .text.text-line-height-2 {
    line-height: 1.5em; }
  .text.text-align-left {
    text-align: left; }
  .text.text-align-right {
    text-align: right; }
  .text.text-align-center {
    text-align: center; }
  .text.text-font-serif {
    font-family: Times, serif; }
  .text.text-status.text-status-success:after {
    border: 1px solid #01dca4;
    background-color: #01dca4;
    border-color: #01dca4; }
  .text.text-status.text-status-neutral:after {
    border: 1px solid #1E86FF;
    background-color: #1E86FF;
    border-color: #1E86FF; }
  .text.text-status.text-status-danger:after {
    border: 1px solid #e44b88;
    background-color: #e44b88;
    border-color: #e44b88; }
  .text.text-status.text-status-progress:after {
    border: 1px solid #ffc266;
    background-color: #ffc266;
    border-color: #ffc266; }
  .text.text-status.text-status-disabled:after {
    border: 1px solid #6F7898;
    background-color: #6F7898;
    border-color: #6F7898; }
  .text.text-status:after {
    content: ' ';
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid #1E86FF;
    background-color: #1E86FF;
    border-color: #1E86FF; }

.btn {
  font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1em;
  padding: 0.6em 1em;
  border-radius: 8px; }
  .btn span {
    position: relative;
    -webkit-transition: all 0.05s ease-in-out;
    transition: all 0.05s ease-in-out;
    top: 0px;
    opacity: 1; }
  .btn .svg-wrap-wrap {
    display: none; }
  .btn:disabled, .btn.btn-disabled {
    opacity: 0.5;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: default;
    pointer-events: none; }
  .btn:focus {
    outline: none; }
  .btn.btn-block {
    display: block;
    width: 100%; }
  .btn.btn-loading .svg-wrap-wrap, .btn.btn-loading:hover .svg-wrap-wrap {
    display: block; }
  .btn.btn-loading span, .btn.btn-loading:hover span {
    top: 3px;
    opacity: 0; }
  .btn.btn-rounded {
    border-radius: 100px; }
  .btn.btn-quadratic {
    padding: 0.6em; }
  .btn.btn-condensed {
    padding: 0.6em; }
  .btn.btn-spaced {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important; }
  .btn.btn-sm {
    font-size: 1em;
    padding: 0.55em 0.875em; }
    .btn.btn-sm.btn-quadratic {
      width: 1.75em;
      height: 1.75em;
      line-height: 1.75em;
      text-align: center;
      padding: 0em; }
    .btn.btn-sm.btn-condensed {
      padding: 0.55em; }
    .btn.btn-sm .icon {
      height: 0.8em;
      width: 0.8em; }
      .btn.btn-sm .icon.icon-only {
        margin-right: 0px; }
  .btn.btn-medium {
    font-size: 1.25em;
    padding: 0.55em 0.875em; }
    .btn.btn-medium.btn-quadratic {
      width: 1.6em;
      height: 1.6em;
      line-height: 1.6em;
      text-align: center;
      padding: 0em; }
    .btn.btn-medium.btn-condensed {
      padding: 0.55em; }
    .btn.btn-medium .icon {
      height: 0.8em;
      width: 0.8em; }
      .btn.btn-medium .icon.icon-only {
        margin-right: 0px; }
  .btn.btn-large {
    font-size: 1.2em;
    line-height: 1.2em;
    padding: 0.55em 0.875em; }
    .btn.btn-large.btn-quadratic {
      width: 1.5em;
      height: 1.5em;
      line-height: 1.5em;
      text-align: center;
      padding: 0em; }
    .btn.btn-large.btn-condensed {
      padding: 0.55em; }
    .btn.btn-large .icon {
      height: 0.8em;
      width: 0.8em; }
      .btn.btn-large .icon.icon-only {
        margin-right: 0px; }
  .btn.btn-small {
    font-size: 0.85em;
    padding: 0.55em 0.875em; }
    .btn.btn-small.btn-quadratic {
      padding: 0.55em; }
    .btn.btn-small.btn-condensed {
      padding: 0.55em; }
    .btn.btn-small .icon {
      height: 0.8em;
      width: 0.8em; }
      .btn.btn-small .icon.icon-only {
        margin-right: 0px; }
  .btn.btn-no-padding {
    padding: 0 !important; }
  .btn .icon {
    height: 0.9em;
    width: 0.9em;
    margin-right: 0.5em; }
  .btn.btn-icon-only .icon {
    margin-right: 0px; }

.btn-default-primary {
  border: none;
  background: #01dca4;
  color: #F8F9FF;
  fill: #F8F9FF;
  border-color: #F8F9FF; }
  .btn-default-primary:active, .btn-default-primary.active, .btn-default-primary.btn-active {
    background: #45fecf;
    color: white;
    fill: white;
    border-color: white; }
  .btn-default-primary:hover, .btn-default-primary.hover {
    background: #017658;
    color: #F8F9FF;
    fill: #F8F9FF, 20%;
    border-color: #92a2ff; }
  .btn-default-primary.btn-loading {
    background: #01b083; }

.btn-default-secondary {
  border: none;
  background: #1E86FF;
  color: #F8F9FF;
  fill: #F8F9FF;
  border-color: #F8F9FF; }
  .btn-default-secondary:active, .btn-default-secondary.active, .btn-default-secondary.btn-active {
    background: #84bdff;
    color: white;
    fill: white;
    border-color: white; }
  .btn-default-secondary:hover, .btn-default-secondary.hover {
    background: #0055b7;
    color: #F8F9FF;
    fill: #F8F9FF, 20%;
    border-color: #92a2ff; }
  .btn-default-secondary.btn-loading {
    background: #0069e4; }

.btn-default-neutral {
  border: none;
  background: #AAB5DB;
  color: #F8F9FF;
  fill: #F8F9FF;
  border-color: #F8F9FF; }
  .btn-default-neutral:active, .btn-default-neutral.active, .btn-default-neutral.btn-active {
    background: #f2f3f9;
    color: white;
    fill: white;
    border-color: white; }
  .btn-default-neutral:hover, .btn-default-neutral.hover {
    background: #6277bd;
    color: #F8F9FF;
    fill: #F8F9FF, 20%;
    border-color: #92a2ff; }
  .btn-default-neutral.btn-loading {
    background: #7385c4; }

.btn-default-danger {
  border: none;
  background: #ea0000;
  color: #F8F9FF;
  fill: #F8F9FF;
  border-color: #F8F9FF; }
  .btn-default-danger:active, .btn-default-danger.active, .btn-default-danger.btn-active {
    background: #ff5151;
    color: white;
    fill: white;
    border-color: white; }
  .btn-default-danger:hover, .btn-default-danger.hover {
    background: #840000;
    color: #F8F9FF;
    fill: #F8F9FF, 20%;
    border-color: #92a2ff; }
  .btn-default-danger.btn-loading {
    background: #bb0000; }

.btn-pastel-secondary {
  border: none;
  background: #E0E4FC;
  color: #1E86FF;
  fill: #1E86FF;
  border-color: #1E86FF; }
  .btn-pastel-secondary:active, .btn-pastel-secondary.active, .btn-pastel-secondary.btn-active {
    background: white;
    color: #84bdff;
    fill: #84bdff;
    border-color: #84bdff; }
  .btn-pastel-secondary:hover, .btn-pastel-secondary.hover {
    background: #8393f3;
    color: #1E86FF;
    fill: #1E86FF, 20%;
    border-color: #0055b7; }
  .btn-pastel-secondary.btn-loading {
    background: #8998f4; }

.btn-flat-neutral {
  border: none;
  background: transparent;
  color: #6F7898;
  fill: #6F7898;
  border-color: #6F7898; }
  .btn-flat-neutral:active, .btn-flat-neutral.active, .btn-flat-neutral.btn-active {
    background: rgba(51, 51, 51, 0);
    color: #aab0c3;
    fill: #aab0c3;
    border-color: #aab0c3; }
  .btn-flat-neutral:hover, .btn-flat-neutral.hover {
    background: rgba(0, 0, 0, 0);
    color: #6F7898;
    fill: #6F7898, 20%;
    border-color: #43495e; }
  .btn-flat-neutral.btn-loading {
    background: rgba(0, 0, 0, 0); }

.btn-flat-neutral-light {
  border: none;
  background: transparent;
  color: #AAB5DB;
  fill: #AAB5DB;
  border-color: #AAB5DB; }
  .btn-flat-neutral-light:active, .btn-flat-neutral-light.active, .btn-flat-neutral-light.btn-active {
    background: rgba(51, 51, 51, 0);
    color: #f2f3f9;
    fill: #f2f3f9;
    border-color: #f2f3f9; }
  .btn-flat-neutral-light:hover, .btn-flat-neutral-light.hover {
    background: rgba(0, 0, 0, 0);
    color: #AAB5DB;
    fill: #AAB5DB, 20%;
    border-color: #6277bd; }
  .btn-flat-neutral-light.btn-loading {
    background: rgba(0, 0, 0, 0); }

.btn-flat-primary {
  border: none;
  background: transparent;
  color: #01dca4;
  fill: #01dca4;
  border-color: #01dca4; }
  .btn-flat-primary:active, .btn-flat-primary.active, .btn-flat-primary.btn-active {
    background: rgba(51, 51, 51, 0);
    color: #45fecf;
    fill: #45fecf;
    border-color: #45fecf; }
  .btn-flat-primary:hover, .btn-flat-primary.hover {
    background: rgba(0, 0, 0, 0);
    color: #01dca4;
    fill: #01dca4, 20%;
    border-color: #017658; }
  .btn-flat-primary.btn-loading {
    background: rgba(0, 0, 0, 0); }

.btn-outlined-neutral {
  border: 2px solid;
  background: transparent;
  color: #6F7898;
  fill: #6F7898;
  border-color: #6F7898; }
  .btn-outlined-neutral:active, .btn-outlined-neutral.active, .btn-outlined-neutral.btn-active {
    background: rgba(51, 51, 51, 0);
    color: #aab0c3;
    fill: #aab0c3;
    border-color: #aab0c3; }
  .btn-outlined-neutral:hover, .btn-outlined-neutral.hover {
    background: rgba(0, 0, 0, 0);
    color: #6F7898;
    fill: #6F7898, 20%;
    border-color: #43495e; }
  .btn-outlined-neutral.btn-loading {
    background: rgba(0, 0, 0, 0); }

.btn-outlined-neutral-light {
  border: 2px solid;
  background: transparent;
  color: #AAB5DB;
  fill: #AAB5DB;
  border-color: #AAB5DB; }
  .btn-outlined-neutral-light:active, .btn-outlined-neutral-light.active, .btn-outlined-neutral-light.btn-active {
    background: rgba(51, 51, 51, 0);
    color: #f2f3f9;
    fill: #f2f3f9;
    border-color: #f2f3f9; }
  .btn-outlined-neutral-light:hover, .btn-outlined-neutral-light.hover {
    background: rgba(0, 0, 0, 0);
    color: #AAB5DB;
    fill: #AAB5DB, 20%;
    border-color: #6277bd; }
  .btn-outlined-neutral-light.btn-loading {
    background: rgba(0, 0, 0, 0); }

.btn-blank-neutral {
  border: none;
  background: transparent;
  color: #AAB5DB;
  fill: #AAB5DB;
  border-color: #AAB5DB;
  padding: 0.5em; }
  .btn-blank-neutral:active, .btn-blank-neutral.active, .btn-blank-neutral.btn-active {
    background: rgba(51, 51, 51, 0);
    color: #f2f3f9;
    fill: #f2f3f9;
    border-color: #f2f3f9; }
  .btn-blank-neutral:hover, .btn-blank-neutral.hover {
    background: rgba(0, 0, 0, 0);
    color: #AAB5DB;
    fill: #AAB5DB, 20%;
    border-color: #6277bd; }
  .btn-blank-neutral.btn-loading {
    background: rgba(0, 0, 0, 0); }
  .btn-blank-neutral:active {
    fill: #6F7898 !important; }
  .btn-blank-neutral:hover {
    background: #ECEFFE; }

.svg-wrap-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%); }

.svg-wrap {
  width: 18px;
  height: 18px; }
  .svg-wrap svg {
    display: block;
    width: 100%;
    height: 100%; }

@-webkit-keyframes rotator {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotator {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes loadpath {
  to {
    stroke-dashoffset: -138; } }

@keyframes loadpath {
  to {
    stroke-dashoffset: -138; } }

div.svg-wrap {
  -webkit-animation: rotator 1.6s linear infinite forwards;
          animation: rotator 1.6s linear infinite forwards; }
  div.svg-wrap circle {
    stroke: rgba(255, 255, 255, 0.9);
    stroke-dasharray: 138;
    stroke-dashoffset: 138;
    -webkit-animation: loadpath 1.6s linear infinite forwards;
            animation: loadpath 1.6s linear infinite forwards; }

.global-navigation {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100vh;
  width: 70px;
  background: #1C2340; }
  .global-navigation .global-navigation-inner {
    width: 40px;
    margin-top: 1.5em;
    margin-left: auto;
    margin-right: auto; }
    .global-navigation .global-navigation-inner .global-navigation-main {
      margin-top: 1em; }
      .global-navigation .global-navigation-inner .global-navigation-main .global-navigation-item {
        margin: 1em 0px; }
        .global-navigation .global-navigation-inner .global-navigation-main .global-navigation-item a {
          fill: #32395E;
          text-decoration: none;
          display: flex;
          border-radius: 4px;
          font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif; }
          .global-navigation .global-navigation-inner .global-navigation-main .global-navigation-item a:hover {
            fill: #E0E4FC;
            color: #E0E4FC; }
          .global-navigation .global-navigation-inner .global-navigation-main .global-navigation-item a.active {
            fill: #E0E4FC;
            color: #E0E4FC; }
          .global-navigation .global-navigation-inner .global-navigation-main .global-navigation-item a .icon-wrapper {
            padding: 0.5em;
            width: 40px;
            height: 40px;
            box-sizing: border-box; }
            .global-navigation .global-navigation-inner .global-navigation-main .global-navigation-item a .icon-wrapper .icon {
              width: 100%;
              height: 100%; }

.avatar {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-rendering: optimizeSpeed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  position: relative;
  -webkit-transition: -webkit-transform 2s;
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  -webkit-transition-delay: 4s;
          transition-delay: 4s;
  border-radius: 50%;
  border: solid transparent; }
  .avatar img {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover; }
  .avatar.avatar-squared {
    border-radius: 6px; }
  .avatar.avatar-style-rainbow-0 {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: black; }
    .avatar.avatar-style-rainbow-0.avatar-img {
      background: #4d4d4d; }
  .avatar.avatar-style-rainbow-1 {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: red; }
    .avatar.avatar-style-rainbow-1.avatar-img {
      background: #e6b3b3; }
  .avatar.avatar-style-rainbow-2 {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: green; }
    .avatar.avatar-style-rainbow-2.avatar-img {
      background: #53c653; }
  .avatar.avatar-style-rainbow-3 {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: purple; }
    .avatar.avatar-style-rainbow-3.avatar-img {
      background: #c653c6; }
  .avatar.avatar-style-rainbow-4 {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: blue; }
    .avatar.avatar-style-rainbow-4.avatar-img {
      background: #b3b3e6; }
  .avatar.avatar-style-default {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: #01dca4; }
    .avatar.avatar-style-default.avatar-img {
      background: #9adccb; }
  .avatar.avatar-style-rainbow-0 {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: black; }
    .avatar.avatar-style-rainbow-0.avatar-img {
      background: #4d4d4d; }
  .avatar.avatar-style-rainbow-1 {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: red; }
    .avatar.avatar-style-rainbow-1.avatar-img {
      background: #e6b3b3; }
  .avatar.avatar-style-rainbow-2 {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: green; }
    .avatar.avatar-style-rainbow-2.avatar-img {
      background: #53c653; }
  .avatar.avatar-style-rainbow-3 {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: purple; }
    .avatar.avatar-style-rainbow-3.avatar-img {
      background: #c653c6; }
  .avatar.avatar-style-rainbow-4 {
    color: #F8F9FF;
    fill: #F8F9FF;
    background: blue; }
    .avatar.avatar-style-rainbow-4.avatar-img {
      background: #b3b3e6; }
  .avatar.avatar-style-neutral {
    color: #151935;
    fill: #151935;
    background: #E0E4FC; }
    .avatar.avatar-style-neutral.avatar-img {
      background: #9adccb; }
  .avatar.avatar-size-40 {
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 14px;
    border-width: 2px; }
  .avatar.avatar-size-35 {
    height: 31px;
    width: 31px;
    line-height: 31px;
    font-size: 12px;
    border-width: 2px; }
  .avatar.avatar-size-25 {
    height: 21px;
    width: 21px;
    line-height: 21px;
    font-size: 8px;
    border-width: 2px; }
  .avatar.avatar-font-size-11 {
    font-size: 1px !important; }

.avatar-group .avatar {
  border-color: #F8F9FF; }

.avatar-group.avatar-group-size-40 ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  left: -2px; }
  .avatar-group.avatar-group-size-40 ul li {
    position: relative;
    display: inline; }
  .avatar-group.avatar-group-size-40 ul li:first-of-type {
    z-index: 5; }
  .avatar-group.avatar-group-size-40 ul li:nth-of-type(2) {
    left: -10px;
    z-index: 4; }
  .avatar-group.avatar-group-size-40 ul li:nth-of-type(3) {
    left: -20px;
    z-index: 3; }
  .avatar-group.avatar-group-size-40 ul li:nth-of-type(4) {
    left: -30px;
    z-index: 2; }
  .avatar-group.avatar-group-size-40 ul li:nth-of-type(5) {
    left: -40px;
    z-index: 1; }

.avatar-group.avatar-group-size-40 .avatar {
  height: 36px;
  width: 36px;
  font-size: 14px;
  line-height: 36px;
  border-width: 2px; }

.avatar-group.avatar-group-size-35 ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  left: -2px; }
  .avatar-group.avatar-group-size-35 ul li {
    position: relative;
    display: inline; }
  .avatar-group.avatar-group-size-35 ul li:first-of-type {
    z-index: 5; }
  .avatar-group.avatar-group-size-35 ul li:nth-of-type(2) {
    left: -8.75px;
    z-index: 4; }
  .avatar-group.avatar-group-size-35 ul li:nth-of-type(3) {
    left: -17.5px;
    z-index: 3; }
  .avatar-group.avatar-group-size-35 ul li:nth-of-type(4) {
    left: -26.25px;
    z-index: 2; }
  .avatar-group.avatar-group-size-35 ul li:nth-of-type(5) {
    left: -35px;
    z-index: 1; }

.avatar-group.avatar-group-size-35 .avatar {
  height: 31px;
  width: 31px;
  line-height: 31px;
  font-size: 12px;
  border-width: 2px; }

.avatar-group.avatar-group-size-23 ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  left: -2px; }
  .avatar-group.avatar-group-size-23 ul li {
    position: relative;
    display: inline; }
  .avatar-group.avatar-group-size-23 ul li:first-of-type {
    z-index: 5; }
  .avatar-group.avatar-group-size-23 ul li:nth-of-type(2) {
    left: -5.75px;
    z-index: 4; }
  .avatar-group.avatar-group-size-23 ul li:nth-of-type(3) {
    left: -11.5px;
    z-index: 3; }
  .avatar-group.avatar-group-size-23 ul li:nth-of-type(4) {
    left: -17.25px;
    z-index: 2; }
  .avatar-group.avatar-group-size-23 ul li:nth-of-type(5) {
    left: -24px;
    z-index: 1; }

.avatar-group.avatar-group-size-23 .avatar {
  height: 21px;
  width: 21px;
  line-height: 21px;
  font-size: 8px;
  border-width: 2px; }

.main-content-layout {
  display: flex;
  flex: 1 1;
  height: 100vh;
  flex-direction: column;
  overflow: hidden; }

.app-flex-wrap {
  height: 100vh;
  flex: 1 1;
  flex-direction: row;
  display: flex;
  background: #F8F9FF; }

.main-content-wrapper {
  padding: 1.5em 2em; }

.subnavigation-wrapper {
  display: flex;
  max-height: 100vh;
  flex: 0 0 220px;
  flex-direction: column;
  overflow: hidden;
  background: #1C2340; }
  .subnavigation-wrapper ul {
    list-style: none;
    margin: 0px;
    margin-top: 1.5em;
    padding: 0px; }
  .subnavigation-wrapper .subnavigation-content {
    padding: 1.5em; }
  .subnavigation-wrapper .subnavigation-heading {
    line-height: 40px;
    text-rendering: geometricprecision;
    white-space: pre;
    font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif;
    font-size: 1.25em;
    font-weight: 600;
    color: #AAB5DB;
    margin: 0px;
    vertical-align: middle; }
  .subnavigation-wrapper .subnav-link {
    fill: #6F7898;
    color: #6F7898;
    text-decoration: none;
    font-size: 0.875em;
    font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif;
    display: block;
    padding: 1em;
    border-radius: 4px;
    outline: 0; }
    .subnavigation-wrapper .subnav-link .subnav-icon-wrapper {
      margin-right: 1em; }
    .subnavigation-wrapper .subnav-link:hover {
      fill: #E0E4FC;
      color: #E0E4FC;
      outline: 0; }
    .subnavigation-wrapper .subnav-link.active {
      fill: #E0E4FC;
      color: #E0E4FC;
      background-color: #151935;
      outline: 0; }
    .subnavigation-wrapper .subnav-link:focus {
      outline: 0; }
  .subnavigation-wrapper .new-course-btn {
    margin-top: 1.5em; }

.dialog-header {
  border-radius: 8px 8px 0px 0px;
  align-items: center;
  display: flex;
  background: #ffffff;
  padding: 1.5em;
  padding-bottom: 1em; }
  .dialog-header .title-wrapper {
    flex-grow: 1; }
  .dialog-header .icon-container {
    display: flex; }
  .dialog-header h3 {
    font-size: 1.5em;
    font-weight: 700;
    color: #32395E;
    font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif; }

.dialog-content {
  background: transparent;
  padding: 1.5em;
  padding-top: 0.5em; }

.dialog-footer {
  display: flex;
  padding: 1em 1.5em;
  border-radius: 0px 0px 8px 8px;
  background: #F8F9FF; }

.success-dialog-content {
  text-align: center; }
  .success-dialog-content img {
    height: 200px;
    margin: 1.5em; }
  .success-dialog-content .explanation-label {
    line-height: 1.5em; }

.user-select-option {
  height: 64px; }
  .user-select-option .avatar-wrapper {
    margin-top: 2px;
    float: left; }
    .user-select-option .avatar-wrapper .avatar {
      height: 24px;
      width: 24px;
      line-height: 24px;
      font-size: 10px;
      margin: auto 0;
      border-width: 2px; }
  .user-select-option .user-info-wrapper {
    line-height: 32px;
    margin-left: 1em;
    float: left; }

textarea.input-field {
  resize: none;
  height: 6em; }

.input-field {
  width: 100%;
  border-radius: 8px;
  background: #ECEFFE;
  color: #6F7898;
  border: 1px solid #ECEFFE;
  margin: 0.5em 0;
  font-size: 0.875em;
  font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif;
  padding: 1em;
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid #ECEFFE;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }
  .input-field.disabled {
    pointer-events: none;
    opacity: 0.7; }
  .input-field::-webkit-input-placeholder {
    font-weight: 600;
    color: #AAB5DB; }
  .input-field::-ms-input-placeholder {
    font-weight: 600;
    color: #AAB5DB; }
  .input-field::placeholder {
    font-weight: 600;
    color: #AAB5DB; }
  .input-field:focus {
    color: #32395E;
    border: 1px solid rgba(186, 218, 255, 0.9);
    box-shadow: 0px 0px 0px 3px rgba(186, 218, 255, 0.2);
    background: #fff;
    outline: none; }

.DayPickerInput input {
  width: 100%;
  color: #6F7898;
  border-radius: 8px;
  background: #ECEFFE;
  margin: 0.5em 0;
  border: 1px solid #ECEFFE;
  font-size: 0.875em;
  font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif;
  padding: 1em;
  display: inline-block;
  box-sizing: border-box;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }
  .DayPickerInput input::-webkit-input-placeholder {
    font-weight: 600;
    color: #AAB5DB; }
  .DayPickerInput input::-ms-input-placeholder {
    font-weight: 600;
    color: #AAB5DB; }
  .DayPickerInput input::placeholder {
    font-weight: 600;
    color: #AAB5DB; }
  .DayPickerInput input:focus {
    color: #32395E;
    border: 1px solid rgba(186, 218, 255, 0.9);
    box-shadow: 0px 0px 0px 3px rgba(186, 218, 255, 0.2);
    background: #fff;
    outline: none; }

.time-slot-picker .slot-select {
  min-width: 200px; }

.create-range-dialog .information-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px; }
  .create-range-dialog .information-block .item {
    flex: 0 1 50%;
    margin-bottom: 1em;
    box-sizing: border-box;
    padding: 5px; }
    .create-range-dialog .information-block .item label {
      font-size: 0.75em;
      color: #AAB5DB;
      margin-bottom: 0.5em;
      display: block; }
    .create-range-dialog .information-block .item span {
      font-size: 1em;
      color: #1C2340;
      display: block; }

.create-range-dialog .DayPickerInput {
  width: 100%; }

.create-range-dialog p {
  font-size: 0.875em;
  line-height: 1.5em; }

.main-header-wrapper {
  padding-bottom: 1em; }

.empty-state-wrapper {
  text-align: center; }
  .empty-state-wrapper .empty-state-image {
    margin-top: 160px;
    margin-bottom: 1em; }
    .empty-state-wrapper .empty-state-image img {
      max-width: 120px; }

.buffl-table .ReactTable {
  border: 0px;
  margin-top: 1em; }
  .buffl-table .ReactTable .rt-resizer {
    width: 10px;
    right: -5px; }

.buffl-table .table-filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em; }
  .buffl-table .table-filter-wrapper .table-filter {
    flex-grow: 1;
    width: 33%;
    max-width: 33%;
    padding: 0 0.5em;
    box-sizing: border-box; }
  .buffl-table .table-filter-wrapper span {
    display: block;
    margin-bottom: 0.5em; }
  .buffl-table .table-filter-wrapper .input-field {
    margin: 0.5em 0 !important; }

.buffl-table .rt-thead .rt-th.-sort-desc, .buffl-table .rt-thead .rt-td.-sort-desc, .buffl-table .rt-thead .rt-th.-sort-asc, .buffl-table .rt-thead .rt-td.-sort-asc {
  box-shadow: none;
  font-weight: 700;
  color: #1E86FF; }

.buffl-table .ReactTable .-pagination .-previous, .buffl-table .ReactTable .-pagination .-next {
  flex: inherit; }

.buffl-table .ReactTable .rt-thead.-header {
  box-shadow: none;
  padding-bottom: 1em;
  font-weight: 700;
  font-size: 0.75em;
  color: #32395E;
  text-transform: uppercase; }

.buffl-table .rt-thead .rt-th, .buffl-table .rt-thead .rt-td {
  padding: 0;
  padding-right: 10px; }

.buffl-table .ReactTable .rt-thead .rt-resizable-header-content {
  text-align: left;
  padding: 0; }

.buffl-table .ReactTable .rt-thead .rt-th, .buffl-table .ReactTable .rt-thead .rt-td {
  border-right: 0; }

.buffl-table .ReactTable .rt-tbody .rt-td {
  border-right: 0;
  text-align: left;
  padding: 15px 0px;
  padding-right: 10px;
  font-size: 0.75em;
  color: #6F7898;
  cursor: pointer; }

.buffl-table .ReactTable .rt-thead.-filters {
  border: none;
  margin-bottom: 1.5em; }

.buffl-table .ReactTable .rt-thead.-filters input, .buffl-table .ReactTable .rt-thead.-filters select {
  background: #ECEFFE;
  color: #6F7898;
  border: 1px solid #ECEFFE;
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid #ECEFFE;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 0.75em; }
  .buffl-table .ReactTable .rt-thead.-filters input:focus, .buffl-table .ReactTable .rt-thead.-filters select:focus {
    color: #32395E;
    border: 1px solid rgba(186, 218, 255, 0.9);
    box-shadow: 0px 0px 0px 3px rgba(186, 218, 255, 0.2);
    outline: none; }

.buffl-table .ReactTable .-pagination .-btn {
  width: 80px;
  opacity: 1;
  color: #1E86FF;
  background: #E0E4FC; }
  .buffl-table .ReactTable .-pagination .-btn:disabled {
    opacity: 0.5; }
  .buffl-table .ReactTable .-pagination .-btn:hover {
    color: #1E86FF;
    background: #eef0fd; }

.buffl-table .-pagination {
  box-shadow: none;
  border: none; }

.buffl-table .ReactTable .-pagination input, .buffl-table .ReactTable .-pagination select {
  border: none;
  border: none;
  background: #e0e4fc82;
  color: #5955d1; }

.buffl-table .ReactTable .rt-tbody {
  overflow: visible; }

.buffl-table .ReactTable .rt-tbody .rt-td.highlight {
  font-weight: 600;
  color: #32395E; }

.buffl-table .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 0px; }
  .buffl-table .ReactTable .rt-tbody .rt-tr-group:hover {
    background: #f3f4fe; }

.buffl-table .ReactTable .rt-tbody .rt-tr {
  padding: 0 6px;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out; }

.buffl-table .ReactTable .rt-tbody .rt-tr.-padRow {
  border-top: 0px; }

.buffl-badge {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 0.68em;
  margin-right: 0.5em;
  font-weight: 600; }
  .buffl-badge.bb-style-default {
    background: #01dca4;
    color: #fff; }
  .buffl-badge.bb-style-success {
    background: #01dca4;
    color: #fff; }
  .buffl-badge.bb-style-danger {
    background: #fa715c;
    color: #fff; }
  .buffl-badge.bb-style-secondary {
    background: #1E86FF;
    color: #fff; }
  .buffl-badge.bb-style-neutral {
    background: #AAB5DB;
    color: #1E86FF; }
  .buffl-badge.bb-style-warning {
    background: #ffc266;
    color: #fff; }

.create-appointments-dialog .information-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px; }
  .create-appointments-dialog .information-block .item {
    margin-bottom: 1em;
    box-sizing: border-box;
    padding: 5px; }
    .create-appointments-dialog .information-block .item label {
      font-size: 0.75em;
      color: #AAB5DB;
      margin-bottom: 0.5em;
      display: block; }
    .create-appointments-dialog .information-block .item span {
      font-size: 1em;
      color: #1C2340;
      display: block; }

.create-appointments-dialog .DayPickerInput {
  width: 100%; }

.create-appointments-dialog p {
  font-size: 0.875em;
  line-height: 1.5em; }

.flex {
  display: flex; }

.buffl-drawer-ui-wrap {
  padding: 0.5em 0;
  line-height: 35px; }
  .buffl-drawer-ui-wrap .col1 {
    padding-top: 5px; }
  .buffl-drawer-ui-wrap .col2 {
    flex-grow: 1;
    padding: 0 10px; }
  .buffl-drawer-ui-wrap .col3 {
    text-align: right;
    opacity: 0;
    -webkit-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out; }
  .buffl-drawer-ui-wrap:hover .col3 {
    opacity: 1; }

.buffl-drawer {
  width: 320px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #ffffff;
  box-sizing: border-box;
  position: relative; }
  .buffl-drawer .bd-header {
    display: flex;
    padding: 1.5em;
    padding-bottom: 0.5em; }
    .buffl-drawer .bd-header .bd-header-left {
      text-align: left;
      flex-grow: 1;
      line-height: 1.6em; }
    .buffl-drawer .bd-header .bd-header-right {
      text-align: right; }
    .buffl-drawer .bd-header button {
      -webkit-transform: translateX(8px) translateY(-8px);
              transform: translateX(8px) translateY(-8px); }
    .buffl-drawer .bd-header h3 {
      font-weight: 500;
      font-size: 1em; }
  .buffl-drawer .buffl-drawer-master {
    position: absolute;
    top: 0;
    left: 0;
    width: 320px;
    height: 100vh; }
  .buffl-drawer .buffl-drawer-master-hide {
    opacity: 0.2;
    left: -320px;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease; }
  .buffl-drawer .buffl-drawer-master-show {
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    left: 0px; }
  .buffl-drawer .buffl-drawer-slave {
    background: #fff;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 320px;
    height: 100vh; }
    .buffl-drawer .buffl-drawer-slave .bd-slave-header {
      display: flex;
      padding: 1.5em 0px;
      padding-bottom: 0.5em; }
      .buffl-drawer .buffl-drawer-slave .bd-slave-header .bd-header-left {
        text-align: left;
        line-height: 1.6em;
        flex-grow: initial !important; }
      .buffl-drawer .buffl-drawer-slave .bd-slave-header .bd-header-right {
        margin-left: 1em;
        text-align: right; }
      .buffl-drawer .buffl-drawer-slave .bd-slave-header button {
        -webkit-transform: translateX(8px) translateY(-8px);
                transform: translateX(8px) translateY(-8px); }
      .buffl-drawer .buffl-drawer-slave .bd-slave-header h3 {
        font-weight: 500;
        font-size: 1em; }
    .buffl-drawer .buffl-drawer-slave.buffl-drawer-slave-show {
      left: 0;
      -webkit-transition: 0.4s all ease-out;
      transition: 0.4s all ease-out;
      box-shadow: none; }
    .buffl-drawer .buffl-drawer-slave.buffl-drawer-slave-hide {
      -webkit-transition: 0.5s all ease;
      transition: 0.5s all ease;
      left: 320px; }
  .buffl-drawer .bd-drawer-page {
    width: 320px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box; }
  .buffl-drawer .bd-content {
    flex-grow: 1; }
    .buffl-drawer .bd-content .bd-scrollbar-content {
      padding: 1.5em;
      padding-top: 0; }
    .buffl-drawer .bd-content .drawer-section {
      margin: 2em 0; }
  .buffl-drawer .drawer-section {
    margin-bottom: 1em; }
    .buffl-drawer .drawer-section .drawer-section-title {
      margin-bottom: 0.5em; }
      .buffl-drawer .drawer-section .drawer-section-title .text-style-section-headline {
        color: #32395E; }
  .buffl-drawer .drawer-item {
    margin-bottom: 0.5em; }
    .buffl-drawer .drawer-item .drawer-action-description {
      margin-bottom: 0.5em; }
    .buffl-drawer .drawer-item .drawer-item-title {
      margin-bottom: 8px; }
      .buffl-drawer .drawer-item .drawer-item-title .text-style-caption {
        color: #6F7898;
        font-weight: 600; }
    .buffl-drawer .drawer-item .drawer-item-body .text-style-description {
      color: #32395E; }
  .buffl-drawer .drawer-comments-empty {
    padding: 0.5em 0; }
  .buffl-drawer .drawer-form .drawer-form-item {
    margin-bottom: 1em;
    box-sizing: border-box; }
    .buffl-drawer .drawer-form .drawer-form-item label {
      font-size: 0.75em;
      color: #AAB5DB;
      margin-bottom: 0.5em;
      display: block; }
    .buffl-drawer .drawer-form .drawer-form-item span {
      font-size: 1em;
      color: #1C2340;
      display: block; }

.confirmation-dialog-content {
  text-align: center; }
  .confirmation-dialog-content img {
    height: 200px;
    margin: 1.5em; }
  .confirmation-dialog-content .explanation-label {
    line-height: 1.5em; }

.alert-box {
  padding: 24px;
  background: #ffc266;
  border-radius: 6px; }
  .alert-box .alert-label {
    font-weight: 700;
    color: #1C2340;
    font-size: 0.875em; }

.comment-wrapper {
  margin-bottom: 1.5em; }
  .comment-wrapper .comment-body {
    padding: 16px 8px;
    background: #F8F9FF;
    border-radius: 6px;
    margin-bottom: 0.5em; }
    .comment-wrapper .comment-body .text-style-description {
      color: #32395E;
      font-size: 0.75em; }
  .comment-wrapper .comment-author {
    display: flex;
    margin-bottom: 8px; }
    .comment-wrapper .comment-author .comment-author-label {
      line-height: 26px;
      font-size: 0.75em;
      margin-left: 0.5em;
      font-weight: 600;
      color: #32395E; }
  .comment-wrapper .date {
    text-align: right;
    flex-grow: 1;
    line-height: 26px; }

.tabs-bar {
  margin-bottom: 2em; }

.menu {
  padding: 0; }
  .menu ul {
    padding: 0;
    display: flex;
    list-style-type: none; }
    .menu ul li {
      float: left;
      margin-left: 0.7em; }
    .menu ul li:first-child {
      margin-left: 0; }
  .menu .menu-btn.btn {
    font-size: 1em;
    border-radius: 5px;
    padding: 0.45em 1.575em;
    font-weight: 600;
    color: #AAB5DB;
    fill: #AAB5DB;
    border: none;
    background: none; }
    .menu .menu-btn.btn.active, .menu .menu-btn.btn.btn-active {
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08), 0 4px 16px 0 rgba(0, 0, 0, 0.16);
      background: #1E86FF;
      color: #F8F9FF !important;
      fill: #F8F9FF; }
    .menu .menu-btn.btn:hover, .menu .menu-btn.btn.hover {
      color: #1E86FF;
      fill: #1E86FF; }

.auth-header {
  margin-bottom: 1em; }

.auth-form .information-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px; }
  .auth-form .information-block .item {
    flex-grow: 1;
    margin-bottom: 1em;
    box-sizing: border-box;
    padding: 5px; }
    .auth-form .information-block .item label {
      font-size: 0.75em;
      color: #AAB5DB;
      margin-bottom: 0.5em;
      display: block; }
    .auth-form .information-block .item span {
      font-size: 1em;
      color: #1C2340;
      display: block; }

.auth-form p {
  font-size: 0.875em;
  line-height: 1.5em; }

.auth-layout-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: #F8F9FF; }

.auth-layout-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  min-height: 101vh;
  background: #F8F9FF;
  box-sizing: border-box;
  padding: 3em 0; }
  .auth-layout-wrapper .auth-layout-page {
    width: 360px;
    background: #fff;
    margin: 0 auto;
    padding: 3em 3em;
    border-radius: 6px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.hublayout-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: #F8F9FF; }

.hublayout-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  min-height: 101vh;
  background: #F8F9FF; }

.field-group {
  font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif; }
  .field-group .label {
    display: inline-block;
    font-size: 0.875em;
    font-weight: 700;
    color: #6F7898;
    margin-bottom: 0.5em; }
  .field-group .error-wrapper {
    text-align: right; }

@-webkit-keyframes BakAppear {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.0; }
  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  80% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

@keyframes BakAppear {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.0; }
  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  80% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }
    .field-group .error-wrapper .error {
      animation-name: BakAppear;
      -webkit-animation-name: BakAppear;
      display: inline-block;
      animation-duration: 0.75s;
      -webkit-animation-duration: 0.75s;
      animation-timing-function: ease-in-out;
      -webkit-animation-timing-function: ease-in-out;
      visibility: visible !important;
      margin-bottom: 1em; }
      .field-group .error-wrapper .error .error-label {
        display: inline-block;
        color: #fff;
        background: #1E86FF;
        font-weight: 700;
        font-size: 0.75em;
        padding: 0.5em;
        border-radius: 3px; }

.field-error-wrapper {
  text-align: right; }

@keyframes BakAppear {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.0; }
  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  80% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }
  .field-error-wrapper .error {
    animation-name: BakAppear;
    -webkit-animation-name: BakAppear;
    display: inline-block;
    animation-duration: 0.75s;
    -webkit-animation-duration: 0.75s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible !important;
    margin-bottom: 1em; }
    .field-error-wrapper .error .error-label {
      display: inline-block;
      color: #fff !important;
      background: #ea0000;
      font-weight: 400;
      font-size: 0.75em !important;
      padding: 0.5em;
      border-radius: 3px; }

.hub .hub-sidebar {
  width: 340px;
  background: #fff;
  position: fixed;
  bottom: 0;
  top: 0;
  overflow: hidden;
  line-height: 1.5em; }
  @media (max-width: 767px) {
    .hub .hub-sidebar {
      position: static;
      width: auto;
      width: initial;
      padding: 0; } }
  .hub .hub-sidebar .hub-sidebar-inner {
    padding: 32px; }
    .hub .hub-sidebar .hub-sidebar-inner .hub-sidebar-logo {
      width: 30%;
      margin-bottom: 1em; }
      .hub .hub-sidebar .hub-sidebar-inner .hub-sidebar-logo img {
        width: 100%; }
    .hub .hub-sidebar .hub-sidebar-inner .hub-sidebar-text h1 {
      font-size: 2.5em;
      line-height: 1.5em;
      color: #151935;
      margin-bottom: 0.5em;
      font-weight: 800; }
      @media (max-width: 767px) {
        .hub .hub-sidebar .hub-sidebar-inner .hub-sidebar-text h1 {
          font-size: 2em; } }
    .hub .hub-sidebar .hub-sidebar-inner .hub-sidebar-text h2 {
      color: #1E86FF;
      font-size: 1.25em;
      line-height: 1.5em;
      font-weight: 600;
      margin-bottom: 2em; }
    .hub .hub-sidebar .hub-sidebar-inner .hub-sidebar-text h3 {
      color: #6F7898;
      display: block;
      line-height: 1em;
      padding: 6px 0;
      font-size: 1.25em; }
    .hub .hub-sidebar .hub-sidebar-inner .hub-sidebar-text p {
      font-weight: 400;
      font-size: 0.875em;
      color: #6F7898;
      line-height: 1.5em; }
    .hub .hub-sidebar .hub-sidebar-inner .hub-sidebar-text a {
      color: #1E86FF;
      text-decoration: none; }

.hub .flex {
  display: flex; }
  .hub .flex.row {
    margin: 0 -5px; }
  @media (max-width: 767px) {
    .hub .flex {
      display: block;
      margin: 0 !important; } }

.hub .row .flexcol {
  padding: 5px; }

.hub .grow {
  flex-grow: 1; }

.hub .DayPickerInput {
  width: 100%; }

.hub .submit {
  text-align: right;
  padding: 1em 0; }

.hub .hub-info-box {
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.16);
  background: #ffc266;
  color: #1C2340;
  fill: #1C2340;
  padding: 1.5em;
  border-radius: 4px;
  margin: 1.5em 0; }
  .hub .hub-info-box .hub-info-box-title {
    font-size: 1em;
    font-weight: 600;
    line-height: 1.5em;
    padding: 0;
    margin-left: 0.5em; }
  .hub .hub-info-box ul {
    list-style: none;
    -webkit-padding-start: 0;
            padding-inline-start: 0; }
    .hub .hub-info-box ul li {
      margin: 1em 0;
      font-size: 0.75em;
      line-height: 1.5em; }

.hub .hub-checklist-item {
  border-bottom: 1px solid #d3d7f459;
  margin-right: 1em;
  display: flex;
  padding: 1em 0; }
  .hub .hub-checklist-item .hub-checklist-text {
    padding-right: 1.5em;
    flex-grow: 1; }
    .hub .hub-checklist-item .hub-checklist-text .hub-checklist-caption {
      font-size: 0.75em;
      margin: 0;
      color: #AAB5DB;
      font-weight: 400;
      line-height: 2.075em; }
    .hub .hub-checklist-item .hub-checklist-text span {
      font-size: 0.875em;
      line-height: 2.075em;
      font-weight: 600;
      color: #1C2340; }
  .hub .hub-checklist-item .hub-checklist-action {
    width: 20%;
    text-align: right; }
    .hub .hub-checklist-item .hub-checklist-action .input-field {
      padding: 0.5em;
      margin: 0;
      text-align: right; }
      .hub .hub-checklist-item .hub-checklist-action .input-field.short-input {
        width: 56px;
        text-align: center; }

.hub .hub-content {
  margin-left: 388px;
  padding: 3em; }
  @media (max-width: 767px) {
    .hub .hub-content {
      margin: 0;
      padding: 1em; } }
  .hub .hub-content label {
    font-size: 0.75em;
    color: #AAB5DB;
    display: block;
    margin-bottom: 0.5em;
    display: inline-block; }
  .hub .hub-content .hub-row {
    background: #fff;
    padding: 1.5em;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    margin: 3em auto; }
    .hub .hub-content .hub-row .hub-info-box {
      box-shadow: none; }
    @media (max-width: 767px) {
      .hub .hub-content .hub-row {
        margin: 1.5em auto; } }
  .hub .hub-content .hub-block {
    display: flex;
    margin: 1.5em auto; }
    @media (max-width: 767px) {
      .hub .hub-content .hub-block {
        display: block; } }
    .hub .hub-content .hub-block .hub-block-left {
      text-align: center;
      min-width: 50px;
      margin-right: 1.5em; }
      @media (max-width: 767px) {
        .hub .hub-content .hub-block .hub-block-left {
          display: none; } }
    .hub .hub-content .hub-block .hub-block-right {
      text-align: left;
      flex-grow: 1; }
  .hub .hub-content .hub-circle {
    text-align: center;
    width: 1em;
    height: 1em;
    display: inline-block;
    background: transparent;
    color: #01dca4;
    font-size: 14px;
    padding: 6px;
    border-radius: 100%;
    line-height: 1em; }
    .hub .hub-content .hub-circle.variant1 {
      color: #1E86FF; }
    .hub .hub-content .hub-circle.variant3 {
      color: #1E86FF;
      background: #badaff; }
  .hub .hub-content .hub-block-icon {
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: #01dca4;
    margin: 0 auto;
    border-radius: 8px; }
    .hub .hub-content .hub-block-icon svg {
      fill: #67fed8; }
    .hub .hub-content .hub-block-icon.variant1 {
      background: #1E86FF; }
      .hub .hub-content .hub-block-icon.variant1 svg {
        fill: #badaff; }
    .hub .hub-content .hub-block-icon.variant2 {
      background: #e44b88; }
      .hub .hub-content .hub-block-icon.variant2 svg {
        fill: #ed86af; }
    .hub .hub-content .hub-block-icon.variant3 {
      background: #65B1ED; }
      .hub .hub-content .hub-block-icon.variant3 svg {
        fill: #b0dafa; }

.hub .information-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px; }
  @media (max-width: 767px) {
    .hub .information-block {
      display: block !important;
      margin: 0 !important; } }
  .hub .information-block .item {
    flex: 0 1 50%;
    margin-bottom: 1em;
    box-sizing: border-box;
    padding: 5px; }
    @media (max-width: 767px) {
      .hub .information-block .item {
        margin-bottom: 0 !important; } }
    .hub .information-block .item label {
      font-size: 0.75em;
      color: #AAB5DB;
      margin-bottom: 0.5em;
      display: inline-block; }
    .hub .information-block .item span {
      font-size: 1em;
      color: #1C2340;
      display: block; }

.hub .ma-header {
  display: flex; }
  .hub .ma-header .ma-header-logo {
    width: 20%; }
    .hub .ma-header .ma-header-logo img {
      width: 60%; }
  .hub .ma-header .ma-header-text {
    width: 80%;
    flex-grow: 1 auto; }

.hub h1 {
  color: #1E86FF;
  font-size: 2em; }

.hub h2 {
  color: #32395E;
  font-size: 1.5em;
  line-height: 50px; }

.hub h3 {
  color: #6F7898;
  display: block;
  line-height: 1em;
  padding: 6px 0;
  font-size: 1.25em; }

.hub p {
  font-size: 0.875em;
  line-height: 1.5em; }

.hub .flex {
  display: flex; }
  .hub .flex.row {
    margin: 0 -5px; }

.hub .row .flexcol {
  padding: 5px; }

.hub .grow {
  flex-grow: 1; }

.hub .DayPickerInput {
  width: 100%; }

.hub .submit {
  text-align: center;
  padding: 1em 0; }

.hub .ma-divider {
  margin: 0 auto;
  margin-top: 2em;
  padding-bottom: 2em;
  border-top: 1px solid #ECEFFE; }

.hub .ma-content label {
  font-size: 0.75em;
  color: #AAB5DB;
  display: block;
  margin-bottom: 0.5em;
  display: inline-block; }

.hub .ma-content .ma-block {
  display: flex;
  margin: 3em auto; }
  .hub .ma-content .ma-block .ma-block-left {
    text-align: center;
    width: 20%; }
  .hub .ma-content .ma-block .ma-block-right {
    text-align: left;
    flex-grow: 1;
    max-width: 80%; }

.hub .ma-content .ma-circle {
  text-align: center;
  width: 1em;
  height: 1em;
  display: inline-block;
  background: transparent;
  color: #01dca4;
  font-size: 14px;
  padding: 6px;
  border-radius: 100%;
  line-height: 1em; }
  .hub .ma-content .ma-circle.variant1 {
    color: #1E86FF; }
  .hub .ma-content .ma-circle.variant3 {
    color: #1E86FF;
    background: #badaff; }

.hub .ma-content .ma-block-icon {
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #01dca4;
  margin: 0 auto;
  border-radius: 8px; }
  .hub .ma-content .ma-block-icon svg {
    fill: #67fed8; }
  .hub .ma-content .ma-block-icon.variant1 {
    background: #1E86FF; }
    .hub .ma-content .ma-block-icon.variant1 svg {
      fill: #badaff; }
  .hub .ma-content .ma-block-icon.variant2 {
    background: #e44b88; }
    .hub .ma-content .ma-block-icon.variant2 svg {
      fill: #ed86af; }
  .hub .ma-content .ma-block-icon.variant3 {
    background: #65B1ED; }
    .hub .ma-content .ma-block-icon.variant3 svg {
      fill: #b0dafa; }

.hub .information-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px; }
  .hub .information-block .item {
    flex: 0 1 50%;
    margin-bottom: 1em;
    box-sizing: border-box;
    padding: 5px; }
    .hub .information-block .item label {
      font-size: 0.75em;
      color: #AAB5DB;
      margin-bottom: 0.5em;
      display: inline-block; }
    .hub .information-block .item span {
      font-size: 1em;
      color: #1C2340;
      display: block; }


@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/NotoSansTagbanwa-Regular.7ab4194d.ttf); }

.app-wrapper {
  font-family: "Open Sans", Lato, Inter, Roboto, Arial, sans-serif;
  font-size: 1em;
  font-weight: 400;
  color: #6F7898; }
  .app-wrapper h1, .app-wrapper h2, .app-wrapper h3, .app-wrapper h4, .app-wrapper h5, .app-wrapper h6 {
    margin: 0;
    outline: none; }
    .app-wrapper h1focus, .app-wrapper h2focus, .app-wrapper h3focus, .app-wrapper h4focus, .app-wrapper h5focus, .app-wrapper h6focus {
      outline: none; }

.display-flex {
  display: flex; }

.flex-wrap-wrap {
  flex-wrap: wrap; }

.flex-justify-end {
  justify-content: flex-end; }

.left-align {
  text-align: left; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.center {
  text-align: center; }

.flex-grow-1 {
  flex-grow: 1; }

.inself-left {
  display: inline-block;
  text-align: left; }

.no-selection ::-moz-selection {
  background: transparent; }

.no-selection ::selection {
  background: transparent; }

.invisible {
  opacity: 0; }

