@import '../../../style/theme/index.scss';


.text {
    margin: 0;
    font-family: $font-sans;
    a{
        cursor: pointer;
    }
    &.truncate{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    &.text-style-body {
        font-size: $font-size-300;
        font-weight: $font-weight-regular;
        color: $neutral-greys-500;
        font-family: $font-sans;
            a{
                fill: black;
                background: red;
                color: black;
                
                
                &:hover {
                    fill: black;
                    background: white;
                    color: black;
                }

                &.active {
                    fill: 'red';
                    background-color: white;
                    color: red;
                }
            }   
    }
    &.text-style-link{
        text-decoration: none;
        font-size: $font-size-200;
        fill: $neutral-greys-500;
        color: $neutral-greys-500;
        font-weight: 100;
        &:hover{
            fill: $neutral-greys-800;
            color: $neutral-greys-800;
        }
        &.active {
            fill: $neutral-greys-500;
            color: $neutral-greys-500;
        }
        
    }
    
    &.text-style-headline{
        font-size: $font-size-500;
        font-weight: $font-weight-bold;
        color: $neutral-greys-600;
        font-family: $font-sans;
    }
    &.text-style-sub-headline{
        font-size: $font-size-400;
        display: block;
        font-weight: $font-weight-semibold;
        line-height: $line-height-3;
        color: $neutral-greys-600;
        font-family: $font-sans;
    }
    &.text-style-section-headline{
        font-size: $font-size-300;
        font-weight: $font-weight-bold;
        color: $neutral-greys-500;
        font-family: $font-sans;
    }
    
    &.text-style-caption{
        font-size: $font-size-75;
        color: $neutral-greys-500;
        font-family: $font-sans;
        font-weight: $font-weight-semibold;
        letterSpacing: '0.015em'
    }
    
    &.text-style-description{
        font-size: $font-size-200;
        color: $neutral-greys-400;
        font-family: $font-sans;
        font-weight: $font-weight-semibold;
    }

    &.text-font-size-75 {
        font-size: $font-size-75;
    }
    &.text-font-size-100 {
        font-size: $font-size-100;
    }
    &.text-font-size-200 {
        font-size: $font-size-200;
    }
    &.text-font-size-300 {
        font-size: $font-size-300;
    }
    &.text-font-size-400 {
        font-size: $font-size-400;
    }
    &.text-font-size-500 {
        font-size: $font-size-500;
    }
    &.text-font-size-600 {
        font-size: $font-size-600;
    }
    &.text-font-size-700 {
        font-size: $font-size-700;
    }
    
    &.text-font-weight-thin{
        font-weight: $font-weight-thin !important;
    }
    &.text-font-weight-regular{
        font-weight: $font-weight-regular !important;
    }
    &.text-font-weight-semibold{
        font-weight: $font-weight-semibold !important;
    }
    &.text-font-weight-bold{
        font-weight: $font-weight-bold !important;
    }

    &.text-line-height-0{
        line-height: $line-height-0;
    }
    &.text-line-height-1{
        line-height: $line-height-1;
    }
    &.text-line-height-2{
        line-height: $line-height-2;
    }
    &.text-align-left{
        text-align: left;
    }
    &.text-align-right{
        text-align: right;
    }
    &.text-align-center{
        text-align:center;
    }
    &.text-font-serif{
        font-family: $font-serif;
    }

    &.text-status{
        &.text-status-success:after {
            
            border: 1px solid $brand-primary-500;
            background-color: $brand-primary-500;
            border-color: $brand-primary-500;
            
        }
        &.text-status-neutral:after {
            border: 1px solid $brand-secondary-500;
            background-color: $brand-secondary-500;
            border-color: $brand-secondary-500;
            
        }
        &.text-status-danger:after {
            
            border: 1px solid $brand-tertiary-500;
            background-color: $brand-tertiary-500;
            border-color: $brand-tertiary-500;
        }
        &.text-status-progress:after {
            
            border: 1px solid $status-warning-300;
            background-color: $status-warning-300;
            border-color: $status-warning-300;
        }
        &.text-status-disabled:after {
            
            border: 1px solid $neutral-greys-500;
            background-color: $neutral-greys-500;
            border-color: $neutral-greys-500;
        }
        
        

        &:after {
            content: ' ';
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-left: 10px;
            
            border-radius: 7px;
            border: 1px solid $brand-secondary-500;
            background-color: $brand-secondary-500;
            border-color: $brand-secondary-500;
          }
    }
}

