@import '../../style/theme/index.scss';

.menu{
    
    padding: 0;
    ul{
        padding: 0;
        display: flex;
        list-style-type: none;
        li{
            float: left;    
            margin-left: 0.7em;
        }
        li:first-child{
            margin-left: 0;
        }
    }

    .menu-btn{
        &.btn{

            font-size: 1em;
            border-radius: 5px;
            padding: 0.45em 1.575em;
            font-weight: $font-weight-semibold;
            $color: $neutral-greys-400; 
            $accent-color: $brand-secondary-500;
            color: $color;
            fill: $color;
            border: none;
            background: none;

            &.active, &.btn-active{
                box-shadow: $shadow-300;
                background: $accent-color;
                color: $neutral-greys-200 !important;
                fill: $neutral-greys-200;
            }

            &:hover, &.hover {
                color: $accent-color;
                fill: $accent-color;
            }
        }
    }
}