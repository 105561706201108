@import '../../style/theme/index.scss';
.subnavigation-wrapper {
    display: flex;
    max-height: 100vh;
    flex: 0 0 220px;
    flex-direction: column;
    overflow:hidden;
    background: $neutral-greys-700;
    ul {
        list-style: none;
        margin:0px;
        margin-top: $space-2;
        padding:0px;
    }
    .subnavigation-content {
        padding: $space-2;
    }
    .subnavigation-heading {
        line-height: 40px;
        text-rendering: geometricprecision;
        white-space: pre;
        font-family: $font-sans;
        font-size: $font-size-400;
        font-weight: $font-weight-semibold;
        color: $neutral-greys-400;
        margin: 0px;
        vertical-align: middle;
    }
    .subnav-link {
    fill: $neutral-greys-500;
    color: $neutral-greys-500;
    text-decoration: none;
    font-size: $font-size-200;
    font-family: $font-sans;
    display: block;
    padding: $space-1;
    border-radius: $radius-0;
    outline: 0;
    .subnav-icon-wrapper {
        margin-right: $space-1;
    }
    &:hover{
        fill: $neutral-greys-300;
        color: $neutral-greys-300;
        outline: 0;
    }
    &.active{
        fill: $neutral-greys-300;
        color: $neutral-greys-300;
        background-color: $neutral-greys-800;
        outline: 0;
    }
    &:focus {
        outline: 0;
    }
    }

    .new-course-btn {
        margin-top: $space-2;
    }
}