@import '../../../style/theme/index.scss';

.hub {

    .ma-header{
        display: flex;
        .ma-header-logo{
            width: 20%;
            img{
                width: 60%;
            }
        }
        .ma-header-text{
            width: 80%;
            flex-grow: 1 auto;
        }
    }
    
    h1 {
        color: $brand-secondary-500;
        font-size: $font-size-600;
    }
    h2 {
        color: $neutral-greys-600;
        font-size: $font-size-500;
        line-height: 50px;
    }
    h3 {
        color: $neutral-greys-500;
        display: block;
        line-height: 1em;
        padding: 6px 0;
        font-size: $font-size-400;
    }
    p {
        font-size: $font-size-200;
        line-height: 1.5em;
    }
    .flex {
        display: flex;
        &.row {
            margin: 0 -5px;
        }
    }
    .row .flexcol {
        padding: 5px;
    }
    .grow {
        flex-grow: 1;
    }
    .DayPickerInput {
        width: 100%;
    }
    .submit {
        text-align: center;
        padding: $space-1 0;
    }
    .ma-divider {
        margin: 0 auto;
        margin-top: $space-3;
        padding-bottom: $space-3;
        border-top: 1px solid $neutral-greys-250;
    }
    .ma-content {
        label {
            font-size: $font-size-100;
            color: $neutral-greys-400;
            display: block;
            margin-bottom: $space-0;
            display: inline-block;
        }
        .ma-block {
            display: flex;
            margin: $space-4 auto;
            .ma-block-left {
                text-align: center;
                width: 20%;
            }
            .ma-block-right {
                text-align: left;
                flex-grow: 1;
                max-width: 80%;
            }
        }
        .ma-circle {
            text-align: center;
            width: 1em;
            height: 1em;
            display: inline-block;
            background: transparent;
            
            color: $brand-primary-500;
            font-size: 14px;
            padding: 6px;
            border-radius: 100%;
            line-height: 1em;
            &.variant1 {
                
                color: $brand-secondary-500;
            }
            &.variant3 {
                
                color: $brand-secondary-500;
                background: $brand-secondary-300;
            }
        }
        .ma-block-icon {
            text-align: center;
            width: 50px;
            height: 50px;
            line-height: 50px;
            background: $brand-primary-500;
            margin: 0 auto;
            border-radius: $radius-2;
            svg {
                fill: $brand-primary-300;
            }
            &.variant1 {
                background: $brand-secondary-500;
                svg {
                    fill: $brand-secondary-300;
                }
            }
            &.variant2 {
                background: $brand-tertiary-500;
                svg {
                    fill: $brand-tertiary-300;
                }
            }
            &.variant3 {
                background: $brand-quaternary-500;
                svg {
                    fill: $brand-quaternary-300;
                }
            }
        }
    }
    .information-block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        .item {
            flex: 0 50%;
            margin-bottom: $space-1;
            box-sizing: border-box;
            padding: 5px;
            label {
                font-size: $font-size-100;
                color: $neutral-greys-400;
                margin-bottom: $space-0;
                display: inline-block;
            }
            span {
                font-size: $font-size-300;
                color: $neutral-greys-700;
                display: block;
            }
        }
    }
}