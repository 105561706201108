@import './../../../style/theme/index.scss';
.buffl-table {
    .ReactTable {
        border:0px;
        margin-top: $space-1;
        .rt-resizer {
            width: 10px;
            right: -5px;
        }
    }
    .table-filter-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -$space-0;
        .table-filter {
            flex-grow: 1;
            width: 33%;
            max-width: 33%;
            padding: 0 $space-0;
            box-sizing: border-box;
        }
        span {
            display: block;
            margin-bottom: $space-0;
        }
        .input-field {
            margin: 0.5em 0 !important;
        }
    }
    .rt-thead .rt-th.-sort-desc, .rt-thead .rt-td.-sort-desc,.rt-thead .rt-th.-sort-asc, .rt-thead .rt-td.-sort-asc {
        box-shadow: none;
        font-weight: $font-weight-bold;
        color: $brand-secondary-500;
        
    }
    .ReactTable .-pagination .-previous, .ReactTable .-pagination .-next{
        flex: inherit;
    }
    .ReactTable .rt-thead.-header {
        box-shadow: none;
        padding-bottom: $space-1;
        font-weight: $font-weight-bold;
        font-size: $font-size-100;
        color: $neutral-greys-600;
        text-transform: uppercase;
    }
   .rt-thead .rt-th, .rt-thead .rt-td {
        padding:0;
        padding-right: 10px;
    }
    .ReactTable .rt-thead .rt-resizable-header-content {
        text-align: left;
        padding:0;
    }
    .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
        border-right: 0;
    }
    .ReactTable .rt-tbody .rt-td {
        border-right: 0;
        text-align: left;
        padding: 15px 0px;
        padding-right: 10px;
        font-size: $font-size-100;
        color: $neutral-greys-500;
        cursor: pointer;

    }
    .ReactTable .-pagination .-btn{
        
    }

    .ReactTable .rt-thead.-filters{
        border: none;
        margin-bottom: $space-2;
    }

    .ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select{
        background: $neutral-greys-250;
        color: $neutral-greys-500;
        border: 1px solid $neutral-greys-250;
        display: inline-block;
        box-sizing: border-box;
        border: 1px solid $neutral-greys-250;
        transition: all 0.2s;
        font-size: $font-size-100;
        &:focus{
            color: $neutral-greys-600;
            border: 1px solid rgba($brand-secondary-300, 0.9);
            box-shadow: 0px 0px 0px 3px rgba($brand-secondary-300, 0.2);
            
            outline: none;
        }
    }

    .ReactTable .-pagination .-btn{
        width: 80px;
        opacity: 1;
        color: $brand-secondary-500;
        background: $neutral-greys-300;
        &:disabled{
            opacity: 0.5;
        }
        &:hover{
            color: $brand-secondary-500;
            background: lighten($neutral-greys-300, 3%);
        }
    }
    .-pagination{
        box-shadow: none;
        border: none
    }
    .ReactTable .-pagination input, .ReactTable .-pagination select{
        border: none;
        border: none;
        background: #e0e4fc82;
        color: #5955d1;
    }
    .ReactTable .rt-tbody {
        overflow: visible;
    }
    .ReactTable .rt-tbody .rt-td.highlight {
        font-weight: $font-weight-semibold;
        color: $neutral-greys-600;
    }
    .ReactTable .rt-tbody .rt-tr-group {
        border-bottom:0px;
        &:hover{
            background: lighten($neutral-greys-300, 4%);
        }
    }
    .ReactTable .rt-tbody .rt-tr {
        padding: 0 6px;
        
        transition: background 0.15s ease-in-out;
    }
    .ReactTable .rt-tbody .rt-tr.-padRow {
        border-top: 0px;
    }
}