.svg-wrap-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .svg-wrap {
    width: 18px;
    height: 18px;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  
  @keyframes rotator {
      to {
          transform: rotate(360deg);
      }
  }
  
  @keyframes loadpath {
    to {
      stroke-dashoffset: -138;
    }
  }
  
  div.svg-wrap {
      animation: rotator 1.6s linear infinite forwards;
  
    circle {
      stroke: rgba(255,255,255,0.9);
      stroke-dasharray: 138;
      stroke-dashoffset: 138;
      animation: loadpath 1.6s linear infinite forwards;
    }
  }
  