@import '../../style/theme/index.scss';

.hub {

    .hub-sidebar{
        width: 340px;
        background:#fff;
        position: fixed;
        bottom: 0;
        top: 0;
        overflow: hidden;
        @media (max-width: 767px) {
            position: static;
            width: initial;
            padding:0;
        }

        .hub-sidebar-inner{
            padding: 32px;
            .hub-sidebar-logo{
                width: 30%;
                margin-bottom: $space-1;
                
                img{
                    width: 100%;
                }
            }
            .hub-sidebar-text{
                h1 {
                    font-size: $font-size-650;
                    line-height: $line-height-2;
                    color: $neutral-greys-800;
                    margin-bottom: $space-0;
                    font-weight: $font-weight-black;
                    @media (max-width: 767px) {
                        font-size: $font-size-600;
                    }
                }
                h2 {
                    color: $brand-secondary-500;
                    font-size: $font-size-400;
                    line-height: $line-height-2;
                    font-weight: $font-weight-semibold;
                    margin-bottom: $space-3;
                }
                h3 {
                    color: $neutral-greys-500;
                    display: block;
                    line-height: 1em;
                    padding: 6px 0;
                    font-size: $font-size-400;
                }
                p {
                    font-weight: $font-weight-regular;
                    font-size: $font-size-200;
                    color: $neutral-greys-500;
                    line-height: $line-height-2;
                    
                }
                a{
                    color: $brand-secondary-500;
                    text-decoration: none;
                }
            }
            
       
    }
    
    line-height: 1.5em;
    }
    .flex {
        display: flex;
        &.row {
            margin: 0 -5px;
        }
        @media (max-width: 767px) {
            display: block;
            margin: 0 !important;
        }
    }
    .row .flexcol {
        padding: 5px;
    }
    .grow {
        flex-grow: 1;
    }
    .DayPickerInput {
        width: 100%;
    }
    .submit {
        text-align: right;
        padding: $space-1 0;
    }
    .hub-info-box{
        box-shadow: $shadow-400;
        background:$status-warning-300;
        color: $neutral-greys-700;
        fill: $neutral-greys-700;
        padding: $space-2;
        border-radius: $radius-0;
        margin: $space-2 0;
            .hub-info-box-title{
                font-size: 1em;
                font-weight: $font-weight-semibold;
                line-height: 1.5em;
                padding: 0;
                margin-left: $space-0;
            }
            ul{
                list-style: none;
                padding-inline-start: 0;
                li{
                    
                    margin: $space-1 0;
                    font-size: $font-size-100;
                    line-height: $line-height-2;    
                }
            }        
    }

    .hub-checklist-item{
            
    
    border-bottom: 1px solid #d3d7f459;
    
        

        margin-right: $space-1;
        display: flex;
        padding: $space-1 0;
        .hub-checklist-text{
        padding-right: $space-2;
        flex-grow: 1;
        .hub-checklist-caption{
            font-size: $font-size-100;
            margin: 0;
            color: $neutral-greys-400;
            font-weight: $font-weight-regular;
            line-height: 2.075em;
            
        }
        span {
            font-size: $font-size-200;
            line-height: 2.075em;
            font-weight: $font-weight-semibold;
            color: $neutral-greys-700;
        }
        }

        .hub-checklist-action{
            width: 20%;
            text-align: right;
            .input-field{
                
                &.short-input{
                    width: 56px;
                    text-align: center;
                }
                padding: 0.5em;
                margin: 0;
                text-align: right;
            }
        }
    }
  
    .hub-content {
        margin-left: 388px;
        padding: $space-4;
        @media (max-width: 767px) {
            margin:0;
            padding: $space-1;
        }
    
        label {
            font-size: $font-size-100;
            color: $neutral-greys-400;
            display: block;
            margin-bottom: $space-0;
            display: inline-block;
        }
        .hub-row{
            background: #fff;
            padding: $space-2;
            box-shadow: $shadow-400;
            border-radius: $radius-0;
            margin: $space-4 auto;
            .hub-info-box{
                box-shadow: none;
            }
            @media (max-width: 767px) {
                margin: $space-2 auto;
            }
        }
        .hub-block {
            display: flex;
            margin: $space-2 auto;

            @media (max-width: 767px) {
                display: block;
            }

            .hub-block-left {
                text-align: center;
                min-width: 50px;
                margin-right: $space-2;
                @media (max-width: 767px) {
                    display: none;
                }
            }
            .hub-block-right {
                text-align: left;
                flex-grow: 1;
                
            }
        }
        .hub-circle {
            text-align: center;
            width: 1em;
            height: 1em;
            display: inline-block;
            background: transparent;
            
            color: $brand-primary-500;
            font-size: 14px;
            padding: 6px;
            border-radius: 100%;
            line-height: 1em;
            &.variant1 {
                
                color: $brand-secondary-500;
            }
            &.variant3 {
                
                color: $brand-secondary-500;
                background: $brand-secondary-300;
            }
        }
        .hub-block-icon {
            text-align: center;
            width: 50px;
            height: 50px;
            line-height: 50px;
            background: $brand-primary-500;
            margin: 0 auto;
            border-radius: $radius-2;
            svg {
                fill: $brand-primary-300;
            }
            &.variant1 {
                background: $brand-secondary-500;
                svg {
                    fill: $brand-secondary-300;
                }
            }
            &.variant2 {
                background: $brand-tertiary-500;
                svg {
                    fill: $brand-tertiary-300;
                }
            }
            &.variant3 {
                background: $brand-quaternary-500;
                svg {
                    fill: $brand-quaternary-300;
                }
            }
        }
    }
    .information-block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        @media (max-width: 767px) {
            display: block !important;
            margin: 0 !important;
        }
        .item {
            flex: 0 50%;
            margin-bottom: $space-1;
            box-sizing: border-box;
            padding: 5px;
            @media (max-width: 767px) {
                margin-bottom: 0 !important;
            }
            label {
                font-size: $font-size-100;
                color: $neutral-greys-400;
                margin-bottom: $space-0;
                display: inline-block;
            }
            span {
                font-size: $font-size-300;
                color: $neutral-greys-700;
                display: block;
            }
        }
    }
}
