@import './style/theme/index.scss';
@import url('https://rsms.me/inter/inter.css');

@font-face {
    font-family: 'Open Sans';
    src: url(./assets/fonts/NotoSansTagbanwa-Regular.ttf);
}


.app-wrapper{
    font-family: $font-sans;
    font-size: $font-size-300;
    font-weight: $font-weight-regular;
    color: $neutral-greys-500;
    h1, h2, h3, h4, h5, h6 {
        margin:0;
        outline: none;
        &focus {
            outline: none;
        }
    }
}