$brand-primary-300: #67fed8;
$brand-primary-500: #01dca4;
$brand-primary-700: #019872;

$brand-secondary-300: #badaff;
$brand-secondary-500: #1E86FF;
$brand-secondary-700: #0050BE;

$brand-tertiary-300: #ed86af;
$brand-tertiary-500: #e44b88;
$brand-tertiary-700: #831440;

$brand-quaternary-300: rgb(176, 218, 250);
$brand-quaternary-500: #65B1ED;
$brand-quaternary-700: #65B1ED;

$status-success-300: #67fed8;
$status-success-500: #01dca4;
$status-success-700: #019872;

$status-danger-300: #ff6262;
$status-danger-500: #ea0000;
$status-danger-700: #950000;

$status-info-300: #928fe0;
$status-info-500: #1E86FF;
$status-info-700: #fa715c;

$status-warning-300: #ffc266;
$status-warning-500: #f90;
$status-warning-700: #995c00;

$neutral-greys-100: #ffffff;
$neutral-greys-200: #F8F9FF;
$neutral-greys-250: #ECEFFE;
$neutral-greys-300: #E0E4FC;
$neutral-greys-350: #D3D8F4;
$neutral-greys-400: #AAB5DB;
$neutral-greys-500: #6F7898;
$neutral-greys-600: #32395E;
$neutral-greys-700: #1C2340;
$neutral-greys-800: #151935;

$rainbow-0: black;
$rainbow-1: red;
$rainbow-2: green;
$rainbow-3: purple;
$rainbow-4: blue;
