@import '../../style/theme/index.scss';

.create-range-dialog{
    
    .information-block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        .item {
            flex: 0 50%;
            margin-bottom: $space-1;
            box-sizing: border-box;
            padding: 5px;
            label {
                font-size: $font-size-100;
                color: $neutral-greys-400;
                margin-bottom: $space-0;
                display: block;
            }
            span {
                font-size: $font-size-300;
                color: $neutral-greys-700;
                display: block;
            }
           
        }
    }
    .DayPickerInput{
        width: 100%;
    }
    p {
        font-size: $font-size-200;
        line-height: 1.5em;
    }
}